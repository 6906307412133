.adm-topbar {
  .dropdown-toggle {
    color: black;
    background-color: transparent;

    &:hover {
      color: black;
      background-color: transparent;
    }
    &:focus {
      color: black;
      background-color: transparent;
    }
    &:active {
      color: black;
      background-color: transparent;
    }
  }

  .adm-logo-title {
    color: white;
    padding-left: 10px;
  }
}
