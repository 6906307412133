.card-stat {
  .avatar-total {
    .avatar-title {
      background-color: #34c38f;
    }
  }

  .avatar-inactive {
    .avatar-title {
      background-color: #ffc107;
    }
  }

  .avatar-pending {
    .avatar-title {
      background-color: #74788d;
    }
  }
}
