.adm-footer-login {
  bottom: 0;
  padding: 20px calc(#{24px} / 2);
  position: absolute;
  right: 0;
  color: #74788d;
  left: 0;
  height: 60px;
  background-color: #f2f2f5;

  @media (max-width: 991.98px) {
    left: 0;
  }
}
