.home-page {
  canvas {
    height: 400px !important;
  }

  .refresh-data {
    cursor: pointer;

    &:hover {
      color: blue;
    }
  }

  .home-transfer-month-chart {
    position: relative;
    min-height: 400px;
  }
}
